import React from 'react';
import { headset } from '@qga/roo-ui/assets';
import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Paragraph,
} from '@qga/roo-ui/components';
import UnauthenticatedLayout from 'src/layouts/UnauthenticatedLayout';

export const EMAIL_LINK =
  'https://www.qantas.com/hotels/contact-us/email?enquiryType=supplier ';
export const PHONE_SUPPORT_OPERATING_TIMES = '8:30am - 5:30pm (AEDT/AEST)';
export const PHONE_SUPPORT_NUMBER = '1300 558 610';

export const ContactUs = () => (
  <UnauthenticatedLayout title="Contact us">
    <Container maxWidth="wide">
      <Flex justifyContent="center">
        <Box width={[1, 1, 5 / 6]}>
          <Heading.h1 mt={6} mb={6}>
            Contact us
          </Heading.h1>
          <Flex alignItems="center" mb={6}>
            <Image alt={'headset icon'} src={headset} />
            <Heading.h2 mb={0} ml={5}>
              Qantas Hotels Partner Services
            </Heading.h2>
          </Flex>
          <Box width={[1, 7 / 10]}>
            <Paragraph mb={5}>
              Contact us for support with the supplier extranet, reservations,
              or if you would like to become part of the network of hotels on
              the Qantas website.
            </Paragraph>
          </Box>
          <Link href={EMAIL_LINK} rel="noopener noreferrer" target="_blank">
            Email us
          </Link>
          <Box mt={6} mb={6}>
            <Paragraph mb={4} data-testid="support-times">
              {PHONE_SUPPORT_OPERATING_TIMES}
              <br />
              Monday to Sunday
            </Paragraph>
            <Link
              href={`tel:${PHONE_SUPPORT_NUMBER}`}
              color="greys.charcoal"
              fontSize="xl"
            >
              {PHONE_SUPPORT_NUMBER}
            </Link>
          </Box>
        </Box>
      </Flex>
    </Container>
  </UnauthenticatedLayout>
);
