import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoginRedirector } from 'src/components/LoginRedirector';
import { PropertyLayout } from 'src/layouts/PropertyLayout';
import { useLegacyRedirects } from 'src/hooks/useLegacyRedirects';
import useProperty from 'src/hooks/useProperty';
import { KeyContacts } from 'src/pages/KeyContacts';
import {
  CreatePromotion,
  EditPromotion,
  PromotionIndex,
} from 'src/pages/Promotions';
import { ContactUs } from 'src/pages/ContactUs';
import {
  AllImages,
  EditImages,
  PropertyImages,
  RoomTypesImages,
  UploadImages,
} from 'src/pages/MediaLibrary';
import ForgotPassword from 'src/pages/ForgotPassword';
import { Inclusions } from 'src/pages/Inclusions';
import { NoMatch } from 'src/pages/NoMatch';
import { PropertyDashboard } from 'src/pages/PropertyDashboard';
import { PropertyFacilities } from 'src/pages/PropertyFacilities';
import { PropertyGroupDashboard } from 'src/pages/PropertyGroupDashboard';
import { PropertyLocation } from 'src/pages/PropertyLocation';
import { PropertyOverview } from 'src/pages/PropertyOverview';
import {
  CreateRatePlan,
  EditRatePlan,
  RatePlansOverview,
} from 'src/pages/RatePlans';
import { BulkUpdateRates, UpdateRates } from 'src/pages/RatesAndInventory';
import { ResetPassword } from 'src/pages/ResetPassword';
import { SignIn } from 'src/pages/SignIn';
import { SignOut } from 'src/pages/SignOut';
import { Terms } from 'src/pages/Terms';
import { ReviewUserDetails, UpdateUserDetails } from 'src/pages/UserDetails';
import { VerifyEmail } from 'src/pages/VerifyEmail';
import { Vip } from 'src/pages/Vip';
import { PrivateRoute } from './components/PrivateRoute';
import { ApiHealthCheck } from 'src/pages/ApiHealthCheck';
import { Reservations } from 'src/pages/Reservations';
import { Reservation } from 'src/pages/Reservation';

export const AppRoutes = () => {
  const property = useProperty();
  const legacyRedirection = useLegacyRedirects();

  return (
    <Switch>
      {legacyRedirection}
      {/* NOTE: redirect from HPP sign in path */}
      <Redirect from="/users/sign_in" to="/sign-in" />

      <PrivateRoute exact={true} path="/" component={LoginRedirector} />

      <Redirect
        exact
        from="/properties/:propertyId/contacts"
        to="/properties/:propertyId/contacts/primary"
      />

      <PrivateRoute path="/properties/:propertyId">
        <PropertyLayout>
          <Switch>
            <Route
              exact
              path="/properties/:propertyId/reservations"
              component={Reservations}
            />
            <Route
              exact
              path="/properties/:propertyId/reservations/:reservationId"
              component={Reservation}
            />
            <Route
              path="/properties/:propertyId/contacts/:contactType"
              component={KeyContacts}
            />

            <Route
              path="/properties/:propertyId/promotions"
              exact
              component={PromotionIndex}
            />

            <Route
              path="/properties/:propertyId/promotions/create"
              component={CreatePromotion}
            />
            <Route
              path="/properties/:propertyId/promotions/:id"
              component={EditPromotion}
            />

            <Redirect
              from="/properties/:propertyId/rate-plans"
              to="/properties/:propertyId/rate-plans/overview"
              exact
            />
            <Route
              path="/properties/:propertyId/rate-plans/overview"
              component={RatePlansOverview}
            />

            <Route
              path="/properties/:propertyId/rate-plans/create"
              component={CreateRatePlan}
            />

            <Route
              path="/properties/:propertyId/rate-plans/:id"
              component={EditRatePlan}
            />

            {property.ratesServiceEnabled && (
              <Route
                path="/properties/:propertyId/rates-and-inventory/:roomTypeId/bulk-update"
                component={BulkUpdateRates}
              />
            )}
            {property.ratesServiceEnabled && (
              <Route
                path="/properties/:propertyId/rates-and-inventory/:roomTypeId"
                component={UpdateRates}
              />
            )}
            <Route
              exact
              path="/properties/:propertyId"
              component={PropertyDashboard}
            />
            <Redirect
              exact
              from="/properties/:propertyId/details"
              to="/properties/:propertyId/details/overview"
            />
            <Route
              exact
              path="/properties/:propertyId/details/overview"
              component={PropertyOverview}
            />

            <Route
              exact
              path="/properties/:propertyId/details/location"
              component={PropertyLocation}
            />

            <Route
              exact
              path="/properties/:propertyId/details/facilities"
              component={PropertyFacilities}
            />

            <Route
              path="/properties/:propertyId/inclusions"
              component={Inclusions}
            />
            <Route
              path="/properties/:propertyId/images/:section/upload"
              component={UploadImages}
            />
            <Route
              path="/properties/:propertyId/images/:section/edit"
              component={EditImages}
            />
            <Route
              path="/properties/:propertyId/images/property"
              component={PropertyImages}
            />
            <Route
              path="/properties/:propertyId/images/room-types"
              component={RoomTypesImages}
            />
            <Route
              path="/properties/:propertyId/images/all"
              component={AllImages}
            />

            <Redirect
              exact
              from="/properties/:propertyId/images"
              to="/properties/:propertyId/images/property"
            />
          </Switch>
        </PropertyLayout>
      </PrivateRoute>

      <PrivateRoute
        path="/property-groups/:propertyGroupId"
        component={PropertyGroupDashboard}
      />

      <PrivateRoute
        exact={true}
        path="/user-details/update"
        component={UpdateUserDetails}
      />

      <PrivateRoute
        exact={true}
        path="/user-details"
        component={ReviewUserDetails}
      />

      <PrivateRoute path="/verify-email" component={VerifyEmail} />

      <Route exact={true} path="/forgot-password" component={ForgotPassword} />
      <Route exact={true} path="/reset-password" component={ResetPassword} />
      <Route exact={true} path="/sign-in" component={SignIn} />
      <Route exact={true} path="/sign-out" component={SignOut} />
      <Route exact={true} path="/contact-us" component={ContactUs} />
      <Route exact={true} path="/terms" component={Terms} />
      <Route exact={true} path="/vip" component={Vip} />
      {/* api-health-check hidden route from users. can only access by manually typing url into nav */}
      <PrivateRoute
        exact={true}
        path="/api-health-check"
        component={ApiHealthCheck}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};
