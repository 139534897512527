import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'normalize.css/normalize.css';
import '@qga/roo-ui/fonts/ciutadella.css';

import { Auth } from '@aws-amplify/auth';
import createCache from '@emotion/cache';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import { config } from 'src/config';
import App from './components/App';
import apolloClient from './lib/apolloClient';
import { theme } from './lib/theme';
import { createBrowserHistory } from 'history';

Auth.configure({
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  region: config.REACT_APP_USER_POOL_REGION,
  // Use sessionStorage (rather than the default local storage) for identity tokens
  // see https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#updating-and-verifying-a-cognito-user-email-address
  storage: sessionStorage,
  userPoolId: config.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: config.REACT_APP_USER_POOL_CLIENT_ID,
  endpoint: config.REACT_APP_IDP_ENDPOINT,
});

const emotionCache = createCache();
emotionCache.compat = true;

const appProps: React.ComponentProps<typeof App> = {
  apolloClient,
  emotionCache,
  theme,
  history: createBrowserHistory(),
};

Sentry.init({
  dsn: config.REACT_APP_SENTRY_DSN,
  environment: config.REACT_APP_ENVIRONMENT,
  release: config.REACT_APP_RELEASE,
  integrations: [new BrowserTracing()],
  // turn off performance sampling for now
  tracesSampleRate: 0,
});

const rootElement = document.getElementById('root');
Modal.setAppElement(rootElement!);

ReactDOM.render(<App {...appProps} />, rootElement);
