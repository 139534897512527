import { rem } from 'polished';
import React, { MouseEventHandler, useCallback, useEffect } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Box, Container, Flex, Heading, Link } from '@qga/roo-ui/components';
import Cookies from 'js-cookie';

import UnauthenticatedLayout from 'src/layouts/UnauthenticatedLayout';
import useAuth from 'src/hooks/useAuth';
import FlexBackgroundImage from 'src/primitives/FlexBackgroundImage';
import ShadowText from 'src/primitives/ShadowText';
import background from './assets/background.jpg';
import { SignInForm } from './components/SignInForm';
import { useLocation } from 'react-router';
import { useAnalytics } from 'src/hooks/useAnalytics';

// This will only remove old client-side cookies, not the HTTP-only cookies
const removeDeprecatedCookie = (cookieName: string) => {
  if (Cookies.get(cookieName)) {
    Cookies.remove(cookieName);
  }
};

export const SignIn: React.FC = () => {
  const location = useLocation<{ from?: string }>();
  const { trackCtaClick } = useAnalytics();
  const { isAuthenticated, signIn } = useAuth();

  useEffect(() => {
    removeDeprecatedCookie('accessToken');
    removeDeprecatedCookie('idToken');
    removeDeprecatedCookie('refreshToken');
  }, []);

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      trackCtaClick({
        group: 'Content',
        item: 'Login',
        text: event.currentTarget.innerText ?? '',
        type: 'link',
        url: event.currentTarget.href,
      });
    },
    [trackCtaClick],
  );

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: location.state?.from ?? '/',
          state: { from: '/sign-in' },
        }}
      />
    );
  }

  return (
    <UnauthenticatedLayout title="Sign in" showBanners showLogos>
      <FlexBackgroundImage src={background} backgroundPosition="right top">
        <Container maxWidth="wide">
          <Flex py={[5, 9]} mx={[-3, 0]}>
            <Box width={[1, rem('450px')]}>
              <Box bg="white" boxShadow="soft" px={[3, 8]} py={[6, 8]}>
                <Heading.h2 as="h1" fontSize={['xl', '2xl']} mb={8}>
                  Sign in to manage your property
                </Heading.h2>

                <SignInForm onSignIn={signIn} />
              </Box>

              <Box pt={3} px={[3, 8]}>
                <ShadowText color="white">
                  By signing in you accept our{' '}
                  {
                    <Link
                      as={HashLink}
                      underline={true}
                      fontWeight="bold"
                      to="/terms#privacy-and-intellectual-property-rights"
                      onClick={handleClick}
                    >
                      privacy policy
                    </Link>
                  }{' '}
                  and{' '}
                  {
                    <Link
                      as={RouterLink}
                      underline={true}
                      fontWeight="bold"
                      to="/terms"
                      onClick={handleClick}
                    >
                      terms of use
                    </Link>
                  }
                  .
                </ShadowText>
              </Box>
            </Box>
          </Flex>
        </Container>
      </FlexBackgroundImage>
    </UnauthenticatedLayout>
  );
};
