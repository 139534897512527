import React, { Suspense } from 'react';
import { GenericLoadingIndicator } from 'src/components/GenericLoadingIndicator';

const LazyReservationPage = React.lazy(
  () => import(/* webpackChunkName: "reservation-page" */ './Reservation'),
);

export const Reservation = (
  props: React.ComponentProps<typeof LazyReservationPage>,
) => {
  return (
    <Suspense fallback={<GenericLoadingIndicator />}>
      <LazyReservationPage />
    </Suspense>
  );
};
