import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Route, RouteProps } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const history = useHistory<{
    forcedPasswordReset?: boolean;
    from?: string;
  }>();

  const fromLocation = props.location?.pathname;

  const forceResetPassword = isAuthenticated && user?.passwordHasExpired;

  useEffect(() => {
    if (forceResetPassword) {
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'force reset pw',
        level: 'info',
      });
      history.replace('/forgot-password', { forcedPasswordReset: true });
    }
  }, [forceResetPassword, history]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      console.warn('REDIRECTING TO SIGN IN');
      history.replace('/sign-in', { from: fromLocation });
    }
  }, [fromLocation, history, isAuthenticated, isLoading]);

  if (forceResetPassword || !isAuthenticated) {
    return null;
  }

  return <Route {...props} />;
};
